import React from 'react';
import Config from '../config';
import PrivatePage from '../common/containers/PrivatePage';
import Content from '../common/containers/Content';
import Ajax from '../common/ajax';
import PDFViewer from '../common/components/PDFViewer/PDFViewer';
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'react-reflex/styles.css';

import { withNamespaces } from 'react-i18next';
import PDFViewerPdfjs from '../common/components/PDFViewer/PDFViewerPdfjs';

class DocumentRevision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      revision: {},
      document: {},
    };
  }

  componentDidMount() {
    this.fetchRevision();
  }

  fetchRevision() {
    const url = `${Config.apiHost}revisions/${this.props.match.params.revisionId}/`;
    Ajax.get(url).done((revision) => {
      this.setState({
        ...this.state,
        revision,
      });
      this.fetchDocument();
    });
  }

  fetchDocument() {
    const url = `${Config.apiHost}documents/${this.state.revision.document}/?only=title`;
    const fields = ['title', 'authors'];
    Ajax.get(url, fields).done((document) => {
      this.setState({
        ...this.state,
        document,
      });
    });
  }

  render() {
    const { i18n } = this.props;
    const { document, revision } = this.state;
    const urls = {
      annotationCreateURL: 'revision-annotations/',
      annotationFetchURL: 'revision-annotations/{id}/',
      annotationUpdateURL: 'revision-annotations/{id}/',
      annotationDestroyURL: 'revision-annotations/{id}/',
      annotationSearchURL: `revision-annotations/search/?revision=${revision.id}`,
    };
    return (
      <PrivatePage title={`${i18n.t('Visualizar revisão')} - ${revision.name}`}>
        <Content noPadding={true}>
          {revision.pdf_url && revision.annotator_version === 'v1' && (
            <PDFViewer
              title={document.title}
              url={revision.pdf_url}
              {...urls}
              annotationExtraProps={{
                revision: revision.id,
              }}
            />
          )}
          {revision.pdf_url && revision.annotator_version === 'v2' && (
            <PDFViewerPdfjs
              title={document.title}
              url={revision.pdf_url}
              {...urls}
              annotationExtraProps={{
                revision: revision.id,
              }}
            />
          )}
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(DocumentRevision);
